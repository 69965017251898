@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
	@variants responsive {
		/* Hide scrollbar for Chrome, Safari and Opera */
		.no-scrollbar::-webkit-scrollbar {
			display: none;
		}

		/* Hide scrollbar for IE, Edge and Firefox */
		.no-scrollbar {
			-ms-overflow-style: none; /* IE and Edge */
			scrollbar-width: none; /* Firefox */
		}
	}
}

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300&display=swap');
